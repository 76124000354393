.grayText {
    color: #888888 !important;
}

.after-price-style {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 12px 0;
}

.after-price-title {
    color: #257cf5;
    font-size: 12px;
    margin-right: 4px;
}

.after-main-price {
    color: #257cf5;
    font-size: 16px;
    font-weight: 500;
}
.icon-position {
    position: absolute;
    right: -14px;
    top: 1px;

}
.position-relative {
    position: relative;
}
.little-margin {
    margin-top: 8px !important;
}