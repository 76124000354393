.titleStyle {
    color: #111111;
    font-size: 16px;
    font-weight: 600;
}

.contentStyle {
    color: #555555;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    width: 324px;
}
.marginBottom {
    margin-bottom: 12px;
}