.travelPackageBox {
    width: 380px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(69, 88, 115, 0.2);
    padding: 25px 16px 16px 16px;
}
.travelpackage-desc {
    display: flex;
    flex-direction: column;
}
.travelpackage-desc__content {
    font-size: 12px;
    color: #666666;
    line-height: 20px;
}
.travelpackage-desc__content2 {
    font-size: 12px;
    color: #666666;
    line-height: 20px;
    padding-top: 8px;
}
.travelpackage__title {
    color: #0f294d;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}
.travelpackage-desc__invoice {
    border-radius: 0 0 4px 4px;
    font-size: 12px;
    color: #666666;
    line-height: 20px;
    padding-top: 4px;
    display: flex;
    flex-direction: row;
    align-items: start;
}
.travelpackage-desc__invoice-title{
    white-space: nowrap;
}
.travelpackage-desc__invoice-content{
    display: flex;
    flex-direction: column;
    width: 288px;
}
.travelpackage-desc__time {
    border-radius: 0 0 4px 4px;
    font-size: 12px;
    color: #666666;
    line-height: 19px;
    padding-top: 5px;
}
.travelpackage-package {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
}
.travelpackage-package__desc{
    color: #455873;
    font-size: 12px;
    font-weight: normal;
    line-height: 20px;
}
.travelpackage-package__content-item {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    border: 0.5px solid #BFEDDD;
    border-radius: 4px;
}
.show-item{
    display: flex;
    flex-direction: row;
    padding-left: 12px;
    background-color: #F2FBF8;
    border-radius: 4px 4px 4px 4px;
    justify-content: center;
    height: 48px;
}
.item-price{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.item-price-desc {
    font-size: 12px;
    font-weight: 500;
    color: #00b87a;
    line-height: 20px;
}
.item-price-content {
    margin-left: 2px;
    font-size: 20px;
    font-weight: 500;
    color: #00b87a;
    line-height: 24px;
    margin-bottom: 5px;
}
.item-content {
    display: flex;
    flex: 4;
    margin-left: 16px;
    align-items: center;
}
.item-tittle {
    font-size: 15px;
    font-weight: 500;
}
.item-date {
    font-size: 12px;
    font-weight: 500;
    color: #999999;
    line-height: 20px;
    margin-left: 8px;
}
.item-desc {
    background: #FFFFFF;
    font-size: 12px;
    color: #888888;
    line-height: 15px;
    padding: 8px 16px;
    justify-content: space-between;
    display: flex;
}
.detail-content-item {
    padding: 0 16px 8px 16px;
    background: #FFFFFF;
    font-size: 12px;
    color: #888888;
    line-height: 16px;
}
.item-light {
    color: #ff7700;
}
.triangle {
    width: 0px;                          
    height: 0px;
    border-bottom: 8px solid #fff;
    border-left: 8px solid transparent;   
    border-right: 8px solid transparent;
    margin: 16px auto 0 auto;
}
.yellowFont {
    color: #ff7700;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;
}
.normalFont {
    color: #8592a6;
    font-size: 12px;
    letter-spacing: 0px;
    font-weight: normal;
    line-height: 20px;
}
.paddingTop {
    padding-top: 8px;
}