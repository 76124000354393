.collapsible {
    max-height: 6em;
    overflow: hidden;
}

.unfoldText {
    cursor: pointer;
    color: #006FF6;
}

.unfoldTextIcon {
    color: #006FF6;
    margin-left: 2px;
}