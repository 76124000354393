.taro-modal-container {
	position: relative;
	z-index: 9999;
	.taro-modal-mask {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		background: #000;
		animation-name: Opacity;
		animation-duration: 0.5s;
		animation-fill-mode: forwards;
	}
	.taro-modal-content {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.flex-content {
		position: relative;
		// margin: auto;
		// width: 75%;
		box-sizing: border-box;
		background: #fff;
		// padding: 32px;
		// border-radius: 16px;
		border-radius: 4px;
		visibility: visible;
	}
}

.modal-title {
	color: #333333;
	font-family: PingFangSC-Medium;
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 84px;
	font-size: 32px;
	margin: 0 84px;
	text-align: center;
}

.modal-btns {
	display: flex;
	justify-content: space-around;
	margin: 0 -12px;
	.modal-btn {
		border-radius: 8px;
		padding: 16px 0;
		font-size: 30px;
		flex: 1;
		text-align: center;
		margin: 0 12px;
	}
	.default {
		color: #0086f6;
		border: 2px solid #2698f7;
	}
	.primary {
		background: #0086f6;
		color: #ffffff;
	}
}

.modal-content {
	margin-top: 16px;
	margin-bottom: 48px;
	color: #333333;
	font-family: PingFangSC-Regular;
	font-size: 30px;
	line-height: 46px;
	.active {
		color: #ff7700;
	}
}

.modal-close {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 84px;
	height: 84px;
	font-size: 40px;
	z-index: 2;
	color: #333;
	.icon-close {
		color: #666;
	}
}

@keyframes Opacity {
	from {
		opacity: 0;
	}

	to {
		opacity: 0.5;
	}
}
